<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-grid">
                <div class="p-col-12 p-md-4">
                    <h5>{{ $t('collaborationPlans.planDetailsTitle') }}</h5>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.name') }} *</label>
                        <div class="p-col-12 p-md-6">
                            <InputText style="width: 100%" type="text" v-model="plan.name" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('general.description') }} *</label>
                        <div class="p-col-12 p-md-6">
                            <InputText style="width: 100%" type="text" v-model="plan.description" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('collaborationPlans.type') }} *</label>
                        <div class="p-col-12 p-md-6">
                            <Dropdown style="width: 100%" type="text" v-model="collaborationPlanModel" optionLabel="name" :options="collaborationPlanTypes" :filter="!this.isMobile()" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('collaborationPlans.amountUsed') }} *</label>
                        <div class="p-col-12 p-md-6">
                            <Dropdown style="width: 100%" type="text" v-model="selectedAmountType" :options="planAmountTypes" :filter="!this.isMobile()" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <div class="p-col-12">
                            <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-4">
                    <h5>{{ $t('collaborationPlans.profitSharingTitle') }}</h5>
                    <div v-if="collaborationPlanModel.id == 0">
                        <div class="p-field p-grid">
                            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('collaborationPlans.from') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="intervals[0].from" />
                            </div>
                            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('collaborationPlans.to') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="intervals[0].to" />
                            </div>
                            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('collaborationPlans.value') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="intervals[0].value" />
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('collaborationPlans.from') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="intervals[1].from" />
                            </div>
                            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('collaborationPlans.to') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="intervals[1].to" />
                            </div>
                            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('collaborationPlans.value') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="intervals[1].value" />
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('collaborationPlans.from') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="intervals[2].from" />
                            </div>
                            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('collaborationPlans.to') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="intervals[2].to" />
                            </div>
                            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('collaborationPlans.value') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="intervals[2].value" />
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="p-field p-grid">
                            <label class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">{{ $t('collaborationPlans.sportbetsValue') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="sportBetsValue" />
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">{{ $t('collaborationPlans.lottoValue') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="lottoValue" />
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">{{ $t('collaborationPlans.virtualsValue') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="virtualsValue" />
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">{{ $t('collaborationPlans.terminalValue') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="terminalValue" />
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-12 p-mb-2 p-md-6 p-mb-md-0">{{ $t('collaborationPlans.nsoftValue') }} :</label>
                            <div class="p-col-12 p-md-2">
                                <InputText style="width: 100%" type="text" v-model="nsoftValue" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-4 p-text-right">
                    <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
                </div>
                <transition-group name="p-message" tag="div" class="p-col-12 p-mb-2">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../../service/BoApi';

export default {
    name: 'edit',
    data() {
        return {
            plan: {},
            BoApi: new BoApi(),
            collaborationPlanTypes: [
                { name: 'Intervals', id: 0 },
                { name: 'Products', id: 1 },
            ],
            collaborationPlanModel: {},
            sportBetsValue: 50,
            lottoValue: 50,
            virtualsValue: 50,
            terminalValue: 50,
            nsoftValue: 50,
            intervals: [
                { from: 0, to: 0, value: 50 },
                { from: 0, to: 0, value: 50 },
                { from: 0, to: 0, value: 50 },
            ],
            disableSubmit: false,
            selectedAmountType: 'PROFIT',
            errorText: '',
            errorKeyCount: 0,
            showError: false,
            backRoute: 'collaborationPlan',
        };
    },
    computed: {
        planAmountTypes() {
            return this.collaborationPlanModel.id == 1 ? ['PROFIT', 'STAKE'] : ['PROFIT'];
        },
    },
    methods: {
        submitForm() {
            this.disableSubmit = true;
            if (this.collaborationPlanModel.id === 0) {
                this.plan.withIntervals = true;
                this.plan.products = null;
                this.plan.intervals = [
                    {
                        startValue: 0,
                        endValue: this.intervals[0].to,
                        percValue: this.intervals[0].value,
                    },
                    {
                        startValue: this.intervals[1].from,
                        endValue: this.intervals[1].to,
                        percValue: this.intervals[1].value,
                    },
                    {
                        startValue: this.intervals[1].from,
                        endValue: 0,
                        percValue: this.intervals[1].value,
                    },
                ];
            } else {
                this.plan.withIntervals = false;
                this.plan.intervals = null;
                this.plan.decontAmount = this.selectedAmountType;
                this.plan.products = [
                    { name: 'SPORTBETS', percValue: this.sportBetsValue },
                    { name: 'LOTTO', percValue: this.lottoValue },
                    { name: 'VIRTUALS', percValue: this.virtualsValue },
                    { name: 'TERMINAL', percValue: this.terminalValue },
                    { name: 'NSOFT', percValue: this.nsoftValue },
                ];
            }
            this.BoApi.saveCollaborationPlan(this.plan)
                .then(() => {
                    this.goto(this.backRoute);
                })
                .catch((error) => {
                    this.disableSubmit = false;
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorText = error;
                    this.errorKeyCount++;
                });
        },
    },
};
</script>
